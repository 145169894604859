(function () {
    'use strict';

    angular.module('aerosApp')
        .factory('OTDRResultsService', OTDRResultsService);

    OTDRResultsService.$inject = ['aerosApi'];

    function OTDRResultsService(aerosApi) {

        var service = {
            getAllResults: getAllResults,
            getResultsByFiberGroup: getResultsByFiberGroup,
            calculatePrePostStatuses: calculatePrePostStatuses,
            calculatePrePostGrouping: calculatePrePostGrouping,
            convertDataSpacingToMeters: convertDataSpacingToMeters
        };

        function getAllResults(params) {
            return aerosApi.getOTDRTestResults(params)
                .then(function (results) {
                    return results.data;
                });
        }

        function getResultsByFiberGroup(params) {
            return aerosApi.getOTDRTestResultsByFibergroup(params)
                .then(function (results) {
                    return results.data;
                });
        }

        function calculatePrePostStatuses(fiberGroups) {

            // set events
            angular.forEach(fiberGroups, function (fiberGroup) {
                if (fiberGroup.results) {
                    angular.forEach(fiberGroup.results.fibers, function (fiber) {
                        angular.forEach(fiber.events, function (event, eventKey) {
                            if (event.type === 'Start') {
                                event.postStatus = event.status;
                            }

                            if (event.type === 'End' && fiber.events[eventKey - 1] && fiber.events[eventKey - 1].status) {
                                event.preStatus = fiber.events[eventKey - 1].status;
                            }

                            if (event.type === 'Event' && fiber.events[eventKey - 1] && fiber.events[eventKey - 1].status) {
                                event.preStatus = fiber.events[eventKey - 1].status;
                            } else {
                                // event.preStatus = event.status;
                            }

                            if (event.type === 'Event' && fiber.events[eventKey + 1] && fiber.events[eventKey + 1].status) {
                                event.postStatus = fiber.events[eventKey + 1].status;
                            } else {
                                // event.postStatus = event.status;
                            }
                        })
                    })
                }
            });

            // set fibers
            angular.forEach(fiberGroups, function (fiberGroup) {
                if (fiberGroup.results) {
                    angular.forEach(fiberGroup.results.fibers, function (fiber) {
                        angular.forEach(fiber.events, function (event, eventKey) {
                            if (event.type === 'Fiber') {
                                event.postStatus = event.status;
                                event.preStatus = event.status;
                                if (eventKey > 0 && fiber.events[eventKey - 1]) {
                                    fiber.events[eventKey - 1].postStatus = event.status;
                                }
                                if (fiber.events[eventKey + 1]) {
                                    fiber.events[eventKey + 1].preStatus = event.status;
                                }
                            }
                        })
                    })
                }
            });
        }

        function calculatePrePostGrouping(fiberGroups) {
            angular.forEach(fiberGroups, function (fiberGroup) {
                    if (fiberGroup.results && fiberGroup.results.fibers) {
                        angular.forEach(fiberGroup.results.fibers, function (fiber) {
                            if (fiber.events) {
                                for(var i = 0; i < fiber.events.length; i++) {
                                    fiber.events[i].GroupingInfo = {
                                        pre: false,
                                        post: false
                                    };
                                    if (i >= 2 && markedForGrouping(fiber.events[i])) {
                                        // set i (current event) pre grouping
                                        fiber.events[i].GroupingInfo.pre = true;
                                        // set i-1 (fiber) both pre and post grouping
                                        fiber.events[i-1].GroupingInfo.pre = true;
                                        fiber.events[i-1].GroupingInfo.post = true;
                                        // set i-2 (previous event) post grouping, and leave pre as it was set before
                                        fiber.events[i-2].GroupingInfo.post = true;

                                    }
                                }
                            }
                        });
                }
            });
        }

        function markedForGrouping(event) {
            return event.isMTP === true;
        }

        function convertDataSpacingToMeters(dataspacing, helixfactor) {
            const SPEED_OF_LIGHT = 300000;
            const _100PsFactor = 10000000000;
            const IOR = 1.5;

            // ((SpeedOfLight / (IOR * HelixFactor))/100PsFactor) * DataSpacingInNs

            return ((SPEED_OF_LIGHT / (IOR * helixfactor)) / _100PsFactor) * (dataspacing / 1000);
        }

        return service;
    }
})();
